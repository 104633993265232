import styled from "styled-components";
import {breakpoints} from "../../styles/breakpoints";

export const StepperTwoArea = styled.main`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 10px;
    max-width: 900px;

    @media ${breakpoints.sm} {
        padding: 30px 20px;
    }

    .campos {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-gap: 20px;

        @media ${breakpoints.md}{
            display: grid;
            grid-template-columns: 1fr;
            padding: 0 10px;
        }

    }
`;