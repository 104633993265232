import React, { useContext, useState } from "react";
import { TextField } from "@mui/material";
import { multiStepContext } from "../../pages/RegisterClient/script";
import { StepOneRegister, StepAreaText, ButtonNextStep } from './styles';

import dayjs from 'dayjs';
import isLeapYear from 'dayjs/plugin/isLeapYear';

dayjs.extend(isLeapYear);

const formatPhone = (value) => {
    value = value.replace(/\D/g, '');

    if (value.length > 11) {
        value = value.substring(0, 11);
    }

    if (value.length <= 10) {
        return value.replace(/(\d{2})(\d{0,4})(\d{0,4})/, '($1) $2-$3').trim();
    } else {
        return value.replace(/(\d{2})(\d{5})(\d{0,4})/, '($1) $2-$3').trim();
    }
};

const isValidCPF = (cpf) => {
    cpf = cpf.replace(/[^\d]+/g, ''); // Remove tudo que não for número
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false; // Verifica se tem 11 dígitos ou todos iguais

    let soma = 0, resto;
    for (let i = 1; i <= 9; i++) soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++) soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    return resto === parseInt(cpf.substring(10, 11));
};

const isValidRG = (rg) => {
    const rgRegex = /^[0-9]{7,9}$/;
    return rgRegex.test(rg);
};

const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
        return "Insira um e-mail!";
    } else if (!regex.test(email)) {
        return "Insira um e-mail válido!";
    }
    return null;
};

const validatePhone = (phone) => {
    if (!phone) {
        return "Insira o telefone!"; // Retorna erro se o telefone não for inserido
    }

    const cleanedPhone = phone.replace(/\D/g, ''); // Remove parênteses, hífens, etc.
    const regex = /^[0-9]{10,11}$/; // Aceita 10 ou 11 dígitos

    if (!regex.test(cleanedPhone)) {
        return "Insira um telefone válido!";
    }
    return null;
};

const formatDateForDisplay = (value) => {
    // Formata para DD/MM/YYYY
    value = value.replace(/\D/g, ''); // Remove tudo que não é número

    if (value.length > 8) {
        value = value.substring(0, 8); // Limita a 8 dígitos
    }

    return value.replace(/(\d{2})(\d{0,2})(\d{0,4})/, '$1/$2/$3').trim(); // Formato DD/MM/YYYY
};

const formatDateForStorage = (value) => {
    return value.replace(/-/g, '/'); // Troca os '-' por '/'
};

const validateDateOfBirth = (date) => {
    if (!date) {
        return "Insira a data de nascimento!";
    }

    // Verifica se a data tem 10 caracteres (DD/MM/YYYY)
    if (date.length !== 10) {
        return "Insira uma data válida no formato DD/MM/YYYY!";
    }

    // Converte DD/MM/YYYY para MM/DD/YYYY para validação
    const [day, month, year] = date.split('/').map(Number);
    const formattedDate = dayjs(new Date(year, month - 1, day)); // MM/DD/YYYY

    if (!formattedDate.isValid()) {
        return "Insira uma data válida!";
    }

    // Verifica se a pessoa é maior de idade (18 anos)
    const today = dayjs();
    const age = today.diff(formattedDate, 'year'); // Calcula a idade em anos

    if (age < 18) {
        return "É necessário ter 18 anos ou mais!";
    }

    return null;
};


export default function StepperOneRegister() {
    const { setStep, userData, setUserData } = useContext(multiStepContext);
    const [errors, setErrors] = useState({});

    // Função para validar os dados do usuário no passo um
    const validateUserDataStepOne = () => {
        const requiredFields = {
            nome: userData["nome"] ? null : "Insira o nome!",
            sobrenome: userData["sobrenome"] ? null : "Insira o seu sobrenome",
            email: validateEmail(userData["email"]),
            telefone: validatePhone(userData["telefone"]),
            cnpj_cpf: userData["cnpj_cpf"] ? (isValidCPF(userData["cnpj_cpf"]) ? null : "Insira um CPF válido!") : "CPF é obrigatório!",
            rg: userData["rg"] ? (isValidRG(userData["rg"]) ? null : "Insira um RG válido") : "RG é obrigatório!",
            data_nascimento: validateDateOfBirth(userData["data_nascimento"]),
            nome_mae: userData["nome_mae"] ? null : "Insira o nome que consta no RG!",
        };

        const newErrors = {};
        let isValid = true;

        // Validação de campos obrigatórios e formatados
        for (let field in requiredFields) {
            const errorMessage = requiredFields[field];

            // Verifica se o campo está vazio ou se a validação falhou
            if (errorMessage) {
                newErrors[field] = errorMessage;
                isValid = false;
            }
        }

        // Define os erros encontrados
        setErrors(newErrors);

        return isValid;
    };

    const handleNextStep = () => {
        if (validateUserDataStepOne()) {
            setStep(2); // Avança para o próximo passo
        } else {
            console.log(errors);
            console.log("Erro de validação");
        }
    };

    return (
        <div>
            <StepOneRegister>
                <h3>Preencha seus dados pessoais</h3>
                <StepAreaText>
                    <TextField
                        label="Nome"
                        value={userData["nome"]}
                        onChange={(e) =>
                            setUserData({ ...userData, nome: e.target.value })
                        }
                        margin="normal"
                        variant="outlined"
                        color="secondary"
                        error={!!errors["nome"]}
                        helperText={errors["nome"] ? errors["nome"] : "Insira o seu primeiro nome."}
                    />
                    <TextField
                        label="Sobrenome"
                        value={userData["sobrenome"]}
                        onChange={(e) =>
                            setUserData({ ...userData, sobrenome: e.target.value })
                        }
                        margin="normal"
                        variant="outlined"
                        color="secondary"
                        error={!!errors["sobrenome"]}
                        helperText={errors["sobrenome"] ? errors["sobrenome"] : "Insira o seu sobrenome."}
                    />
                    <TextField
                        label="E-mail"
                        value={userData["email"]}
                        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                        margin="normal"
                        variant="outlined"
                        color="secondary"
                        error={!!errors["email"]}
                        helperText={errors["email"] ? errors["email"] :
                            "Preencha seu e-mail."}
                    />
                    <TextField
                        label="Telefone"
                        value={userData["telefone"]}
                        onChange={(e) => {
                            const formattedPhone = formatPhone(e.target.value);
                            setUserData({ ...userData, telefone: formattedPhone });
                        }}
                        margin="normal"
                        variant="outlined"
                        color="secondary"
                        error={!!errors["telefone"]}
                        helperText={errors["telefone"] || "Insira o telefone!"}
                    />
                    <TextField
                        label="Telefone 2 (opcional)"
                        value={userData["telefone_opcional"]}
                        onChange={(e) => {
                            const formattedOptionalPhone = formatPhone(e.target.value);
                            setUserData({ ...userData, telefone_opcional: formattedOptionalPhone });
                        }}
                        margin="normal"
                        variant="outlined"
                        color="secondary"
                        error={!!errors["telefone_opcional"]}
                        helperText={errors["telefone_opcional"] || "Insira um telefone opcional!"}
                    />
                    <TextField
                        label="CPF"
                        margin="normal"
                        value={userData["cnpj_cpf"]}
                        onChange={(e) => setUserData({ ...userData, cnpj_cpf: e.target.value })}
                        variant="outlined"
                        color="secondary"
                        error={!!errors["cnpj_cpf"]}
                        helperText={errors["cnpj_cpf"] ? errors["cnpj_cpf"] : "Insira o seu CPF."}
                    />
                    <TextField
                        label="RG"
                        margin="normal"
                        value={userData["rg"]}
                        onChange={(e) => setUserData({ ...userData, rg: e.target.value })}
                        variant="outlined"
                        color="secondary"
                        type="number"
                        error={!!errors["rg"]}
                        helperText={errors["rg"] ? errors["rg"] : "Insira o seu RG."}
                    />

                    <TextField
                        label="Data de Nascimento"
                        type="text" // Manter como 'text'
                        value={userData["data_nascimento"]}
                        onChange={(e) => {
                            const formattedDate = formatDateForDisplay(e.target.value);
                            setUserData({ ...userData, data_nascimento: formattedDate });
                        }}
                        margin="normal"
                        variant="outlined"
                        color="secondary"
                        error={!!errors["data_nascimento"]}
                        helperText={errors["data_nascimento"] ? errors["data_nascimento"] : "Insira a sua data de nascimento."}
                    />

                    <TextField
                        label="Nome da mãe"
                        value={userData["nome_mae"]}
                        onChange={(e) => setUserData({ ...userData, nome_mae: e.target.value })}
                        margin="normal"
                        variant="outlined"
                        color="secondary"
                        error={!!errors["nome_mae"]}
                        helperText={errors["nome_mae"]}
                    />
                </StepAreaText>
                <ButtonNextStep onClick={handleNextStep}>
                    PRÓXIMO
                </ButtonNextStep>

            </StepOneRegister>
        </div>
    );
}