// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './pages/Home/index';
import RegisterClient from './pages/RegisterClient/index';
import Login from './pages/Login/login';

import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Admin from './pages/AuthPages/Admin/Main/main';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<RegisterClient />} />
        <Route path="/login" element={<Login />} />

        <Route 
            path="/admin" 
            element={
                <PrivateRoute>
                    <Admin />
                </PrivateRoute>
            } 
        />
      </Routes>
      
    </Router>
  );
}

export default App;
