import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
    const token = localStorage.getItem('token'); // Verifica se o token está no localStorage

    return token ? children : <Navigate to="/login" />; // Se tiver o token, renderiza a página /admin, se não, redireciona para /login
};

export default PrivateRoute;
