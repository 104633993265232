import React from "react";
import './styled.js';
import { useNavigate } from "react-router-dom";

import { TbCircleCheckFilled } from "react-icons/tb";
import { IoRocketOutline } from "react-icons/io5";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';

import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';

import Logo from '../../assets/logo_branca.png';

import {
    SalesList,
    SalesListArea,
    Header
} from './styled.js';

const planos = {
    "300 MEGA": [
        "ATÉ 150 MEGA DE UP",
        "ROTEADOR WI-FI INCLUSO",
        "INSTALAÇÃO GRÁTIS",
        "SUPORTE TÉCNICO 24HS",
        "NETCENTER TV",
        "+ 200 CURSOS LEVEDUCA",
    ],
    "500 MEGA": [
        "ATÉ 250 MEGA DE UP",
        "ROTEADOR WI-FI INCLUSO",
        "INSTALAÇÃO GRÁTIS",
        "SUPORTE TÉCNICO 24HS",
        "NETCENTER TV",
        "+ 200 CURSOS LEVEDUCA",
        "+ 02 APPS STANDARD"
    ],
    "700 MEGA": [
        "ATÉ 350 MEGA DE UP",
        "ROTEADOR WI-FI INCLUSO",
        "INSTALAÇÃO GRÁTIS",
        "SUPORTE TÉCNICO 24HS",
        "NETCENTER TV",
        "+ 200 CURSOS LEVEDUCA",
        "+ 04 APPS STANDARD"
    ],
    "1000 MEGA": [
        "ATÉ 500 MEGA DE UP",
        "ROTEADOR WI-FI INCLUSO",
        "INSTALAÇÃO GRÁTIS",
        "SUPORTE TÉCNICO 24HS",
        "NETCENTER TV",
        "+ 200 CURSOS LEVEDUCA",
        "+ 06 APPS STANDARD"
    ],
};

function Home() {

    const navigate = useNavigate();

    function handleContratarClick(planTitle, planValue) {
        const urlParams = new URLSearchParams(window.location.search);
        const utmTerm = urlParams.get('utm_term');
    
        const selectedPlan = {
            titulo: planTitle,
            valor: planValue,
            beneficios: planos[planTitle], // Adiciona os benefícios ao objeto selecionado
        };
    
        // Redireciona para a página /register e passa os dados no state
        navigate('/register', { state: { planTitle, planValue, utmTerm, beneficios: selectedPlan.beneficios } });
    }
    


    return (
        <div>
            <Header>
                <span style={{textAlign: 'right', alignSelf: 'end', marginRight: '10%', padding: 10, color: '#c2c2c2'}}>Vida a experiência Netcenter</span>
                <div className="headerContainer">
                    <div className="headerContato">
                        <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                            <PhoneIcon sx={{color: '#c2c2c2'}}/>
                            <a href="https://api.whatsapp.com/send/?phone=%2B558006800002&text&type=phone_number&app_absent=0">0800 680 0002</a>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                            <WhatsAppIcon sx={{color: '#c2c2c2'}}/>
                            <a href="https://api.whatsapp.com/send/?phone=%2B558006800002&text&type=phone_number&app_absent=0">0800 680 0002</a>
                        </div>
                    </div>
                    <div style={{gridArea: 'logo'}}>
                        <img alt="" src={Logo}/>
                    </div>
                </div>
            </Header>

            <SalesList>

                <SalesListArea>

                    {/* Primeiro plano */}
                    <div className="salesPlans">
                        <div className="salesPlansContainer">
                            <div className="salesPlansTitleArea">
                                {/*   
                                <img alt="" width={150} height={150} />
                                */}
                                <div style={{ position: 'relative' }}>
                                    <Gauge
                                        width={150}
                                        height={150}
                                        value={75}
                                        text={""}
                                        cornerRadius="50%"
                                        sx={(theme) => ({
                                            [`& .${gaugeClasses.valueText}`]: {
                                                fontSize: 16,
                                            },
                                            [`& .${gaugeClasses.valueArc}`]: {
                                                fill: '#FFCE00',
                                            },
                                            [`& .${gaugeClasses.referenceArc}`]: {
                                                fill: theme.palette.text.disabled,
                                            },
                                        })}
                                    />
                                    <IoRocketOutline size={35} color="#FFCE00" style={{ position: 'absolute', top: 55, left: 60 }} />
                                </div>
                                <p>COMBO DE ATÉ</p>
                                <span id="planTitle">300 MEGA</span>
                            </div>

                            <div className="salesPlansDetails">
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>ATÉ 250 MEGA DE UP</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>ROTEADOR WI-FI INCLUSO</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>INSTALAÇÃO GRÁTIS</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>SUPORTE TÉCNICO 24HS</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>NETCENTER TV</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>+ 200 CURSOS LEVEDUCA</span>
                                </div>
                            </div>
                            <div className="salesPlanValueArea">
                                <h3>R$</h3>
                                <span id="planValue">79,90</span>
                                <p>/mês</p>
                            </div>
                            <p style={{ padding: 20, fontSize: 16, textAlign: 'center' }}>*Valor válido para planos fidelizados por 12 meses</p>
                            {/* Ao clicar, chama a função handleContratarClick com o título e valor do plano */}
                            <button onClick={() => handleContratarClick("300 MEGA", "79,90")}>CONTRATAR</button>
                        </div>
                    </div>
                    
                    <div className="salesPlans">
                        <div className="salesPlansContainer">
                            <div className="salesPlansTitleArea">
                                {/*   
                                <img alt="" width={150} height={150} />
                                */}
                                <div style={{ position: 'relative' }}>
                                    <Gauge
                                        width={150}
                                        height={150}
                                        value={85}
                                        text={""}
                                        cornerRadius="50%"
                                        sx={(theme) => ({
                                            [`& .${gaugeClasses.valueText}`]: {
                                                fontSize: 16,
                                            },
                                            [`& .${gaugeClasses.valueArc}`]: {
                                                fill: '#FFCE00',
                                            },
                                            [`& .${gaugeClasses.referenceArc}`]: {
                                                fill: theme.palette.text.disabled,
                                            },
                                        })}
                                    />
                                    <IoRocketOutline size={35} color="#FFCE00" style={{ position: 'absolute', top: 55, left: 60 }} />
                                </div>
                                <p>COMBO DE ATÉ</p>
                                <span id="planTitle">500 MEGA</span>
                            </div>

                            <div className="salesPlansDetails">
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>ATÉ 350 MEGA DE UP</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>ROTEADOR WI-FI INCLUSO</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>INSTALAÇÃO GRÁTIS</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>SUPORTE TÉCNICO 24HS</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>NETCENTER TV</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>+ 200 CURSOS LEVEDUCA</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>+ 02 APPS STANDART</span>
                                </div>
                            </div>
                            <div className="salesPlanValueArea">
                                <h3>R$</h3>
                                <span id="planValue">99,90</span>
                                <p>/mês</p>
                            </div>
                            <p style={{ padding: 20, fontSize: 16, textAlign: 'center' }}>*Valor válido para planos fidelizados por 12 meses</p>
                            {/* Ao clicar, chama a função handleContratarClick com o título e valor do plano */}
                            <button onClick={() => handleContratarClick("500 MEGA", "99,90")}>CONTRATAR</button>
                        </div>
                    </div>

                    <div className="salesPlans">
                        <div className="salesPlansContainer">
                            <div className="salesPlansTitleArea">
                                {/*   
                                <img alt="" width={150} height={150} />
                                */}
                                <div style={{ position: 'relative' }}>
                                    <Gauge
                                        width={150}
                                        height={150}
                                        value={90}
                                        text={""}
                                        cornerRadius="50%"
                                        sx={(theme) => ({
                                            [`& .${gaugeClasses.valueText}`]: {
                                                fontSize: 16,
                                            },
                                            [`& .${gaugeClasses.valueArc}`]: {
                                                fill: '#FFCE00',
                                            },
                                            [`& .${gaugeClasses.referenceArc}`]: {
                                                fill: theme.palette.text.disabled,
                                            },
                                        })}
                                    />
                                    <IoRocketOutline size={35} color="#FFCE00" style={{ position: 'absolute', top: 55, left: 60 }} />
                                </div>
                                <p>COMBO DE ATÉ</p>
                                <span id="planTitle">700 MEGA</span>
                            </div>

                            <div className="salesPlansDetails">
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>ATÉ 350 MEGA DE UP</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>ROTEADOR WI-FI INCLUSO</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>INSTALAÇÃO GRÁTIS</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>SUPORTE TÉCNICO 24HS</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>NETCENTER TV</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>+ 200 CURSOS LEVEDUCA</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>+ 04 APPS STANDART</span>
                                </div>
                            </div>
                            <div className="salesPlanValueArea">
                                <h3>R$</h3>
                                <span id="planValue">119,90</span>
                                <p>/mês</p>
                            </div>
                            <p style={{ padding: 20, fontSize: 16, textAlign: 'center' }}>*Valor válido para planos fidelizados por 12 meses</p>
                            {/* Ao clicar, chama a função handleContratarClick com o título e valor do plano */}
                            <button onClick={() => handleContratarClick("700 MEGA", "119,90")}>CONTRATAR</button>
                        </div>
                    </div>

                    <div className="salesPlans">
                        <div className="salesPlansContainer">
                            <div className="salesPlansTitleArea">
                                {/*   
                                <img alt="" width={150} height={150} />
                                */}
                                <div style={{ position: 'relative' }}>
                                    <Gauge
                                        width={150}
                                        height={150}
                                        value={100}
                                        text={""}
                                        cornerRadius="50%"
                                        sx={(theme) => ({
                                            [`& .${gaugeClasses.valueText}`]: {
                                                fontSize: 16,
                                            },
                                            [`& .${gaugeClasses.valueArc}`]: {
                                                fill: '#FFCE00',
                                            },
                                            [`& .${gaugeClasses.referenceArc}`]: {
                                                fill: theme.palette.text.disabled,
                                            },
                                        })}
                                    />
                                    <IoRocketOutline size={35} color="#FFCE00" style={{ position: 'absolute', top: 55, left: 60 }} />
                                </div>
                                <p>COMBO DE ATÉ</p>
                                <span id="planTitle">1000 MEGA</span>
                            </div>

                            <div className="salesPlansDetails">
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>ATÉ 500 MEGA DE UP</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>ROTEADOR WI-FI INCLUSO</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>INSTALAÇÃO GRÁTIS</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>SUPORTE TÉCNICO 24HS</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>NETCENTER TV</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>+ 200 CURSOS LEVEDUCA</span>
                                </div>
                                <div className="salesIconDescriptionArea">
                                    <TbCircleCheckFilled color="#FFCE00" size={25} />
                                    <span>+ 06 APPS STANDART</span>
                                </div>






                            </div>

                            <div className="salesPlanValueArea">
                                <h3>R$</h3>
                                <span id="planValue">179,90</span>
                                <p>/mês</p>
                            </div>

                            <p style={{ padding: 20, fontSize: 16, textAlign: 'center' }}>*Valor válido para planos fidelizados por 12 meses</p>

                            {/* Ao clicar, chama a função handleContratarClick com o título e valor do plano */}
                            <button onClick={() => handleContratarClick("1000 MEGA", "179,90")}>CONTRATAR</button>
                        </div>
                    </div>

                </SalesListArea>

            </SalesList>
        </div>
    );
};

export default Home;
