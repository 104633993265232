import styled from "styled-components";

export const Body = styled.body`
    display: flex;
    flex-direction: column;
    background-color: #c2c2c2;
    height: 100vh;
`;

export const Header = styled.header`
    display: flex;
    width: 100%;
    height: 80px;


`;

export const Main = styled.main`


    #btnEntrar {
        padding: 10px;
        margin: 10px;
        border-radius: 5px;
        background-color: #c2c2c2;
        border-color: green;
    }
`;