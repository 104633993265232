export const size = {
    sm: '360px',
    md: '680px',
    bg: '1024px'
}

export const breakpoints = {
    sm: `(max-width: ${size.sm})`,
    md: `(max-width: ${size.md})`,
    bg: `(max-width: ${size.bg})`
}

