import React from "react";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import SellIcon from '@mui/icons-material/Sell';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
    Body,
    Header,
    Main
} from './styled';


const Admin = () => {
    return (
        <Body>
            <Main>
                <div className="aside">
                    <div>
                        <span>
                            Netcenter Vendas
                        </span>
                    </div>
                </div>
                <div className="main">
                    <div className="mainArea">
                        <div>
                            <Accordion className="accordion-link" sx={{ width: '100%', borderRadius: 10 }}>
                                <AccordionSummary
                                    expandIcon={<ArrowDropDownIcon />}
                                    aria-controls="panel2-content"
                                    id="panel2-header"
                                    sx={{ background: '#1d2261' }}
                                >
                                    <h3>Link de vendas</h3>
                                </AccordionSummary >
                                <AccordionDetails>
                                    <Typography style={{display: 'flex', alignItems: 'center', gap: 10}}>
                                        <p className="link-utm">https://vendasnetcentertelecom.com.br/?utm_term=12345678</p>
                                        <ContentCopyIcon/>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="section-vendas-realizadas">
                            <div className="header-vendas">
                                <div>
                                    <h3>Produção</h3>
                                </div>
                                <div style={{display: 'flex', gap: 10, margin: '0 10px'}}>
                                    <div>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker label="Data início" />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                    <div>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DemoContainer components={['DatePicker']}>
                                                <DatePicker label="Data final" />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </div>
                            <div className="main-vendas">
                                <div className="saldo-area">
                                    <SellIcon style={{color: `#454547`}}/>
                                    <span>0 Vendas</span>
                                </div>
                                <div style={{margin: '20px 0', textAlign: 'center'}}>Gráfico</div>
                            </div>


                        </div>
                    </div>
                </div>

            </Main>
        </Body>
    );
};

export default Admin;
