import React, { useContext, useState } from "react";
import { Button, TextField, MenuItem, Select, FormControl, InputLabel, FormHelperText } from "@mui/material";
import { multiStepContext } from "../../pages/RegisterClient/script";
import { StepperTwoArea } from './styled';
import { ButtonNextStep } from '../StepperOneRegister/styles';



export default function StepperTwoRegister() {
    const { setStep, userData, setUserData } = useContext(multiStepContext);
    const [errors, setErrors] = useState({});

    const validateCEP = (cep) => {
        const cepRegex = /^\d{5}-\d{3}$/;
        return cepRegex.test(cep);
    };

    const handleCEPChange = (e) => {
        const value = e.target.value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos

        let formattedValue = "";
        if (value.length <= 5) {
            formattedValue = value; // Exibe apenas os primeiros 5 dígitos
        } else if (value.length <= 8) {
            formattedValue = `${value.slice(0, 5)}-${value.slice(5)}`; // Formata como 00000-000
        } else {
            formattedValue = `${value.slice(0, 5)}-${value.slice(5, 8)}`; // Limita a 8 dígitos
        }

        setUserData({ ...userData, "cep": formattedValue });
    };

    const handleNextStep = () => {
        const cepError = validateCEP(userData["cep"]) ? null : "Insira um CEP válido";
        const logradouroError = userData["logradouro"] ? null : "O logradouro não pode ficar vazio.";
        const enderecoError = userData["endereco"] ? null : "O endereço não pode ficar vazio.";
        const numeroError = userData["numero_casa"] ? null : "O número não pode ficar vazio.";
        const bairroError = userData["bairro"] ? null : "O bairro não pode ficar vazio.";
        const cidadeError = userData["cidade"] ? null : "A cidade não pode ficar vazia.";
        const referenciaError = userData["referencia"] ? null : "A referência não pode ficar vazia.";

        setErrors({
            cep: cepError,
            logradouro: logradouroError,
            endereco: enderecoError,
            numero_casa: numeroError,
            bairro: bairroError,
            cidade: cidadeError,
            referencia: referenciaError,
        });

        // Verifica se há erros
        if (!cepError && !logradouroError && !enderecoError && !numeroError && !bairroError && !cidadeError && !referenciaError) {
            setStep(3); // Avança para o próximo passo
        }
    };

    return (
        <StepperTwoArea>
            <h3>Preencha o seu endereço!</h3>
            <div className="campos">
                <TextField
                    label="CEP"
                    value={userData["cep"]}
                    onChange={handleCEPChange}
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    error={!!errors["cep"]}
                    helperText={errors["cep"] ? errors["cep"] : "Insira seu CEP no formato 00000-000"}
                />
                <FormControl margin="normal" variant="outlined" color="secondary">
                    <InputLabel>Logradouro</InputLabel>
                    <Select
                        label="Logradouro"
                        value={userData["logradouro"] || ""}
                        onChange={(e) => setUserData({ ...userData, "logradouro": e.target.value })}
                    >
                        <MenuItem value="Rua">Rua</MenuItem>
                        <MenuItem value="Alameda">Alameda</MenuItem>
                        <MenuItem value="Avenida">Avenida</MenuItem>
                        <MenuItem value="Arraial">Arraial</MenuItem>
                        <MenuItem value="Estrada">Estrada</MenuItem>
                        <MenuItem value="Parque">Parque</MenuItem>
                        <MenuItem value="Praça">Praça</MenuItem>
                        <MenuItem value="Rodovia">Rodovia</MenuItem>
                        <MenuItem value="Vila">Vila</MenuItem>
                        <MenuItem value="Aeroporto">Aeroporto</MenuItem>
                        <MenuItem value="Área">Área</MenuItem>
                        <MenuItem value="Campo">Campo</MenuItem>
                        <MenuItem value="Chácara">Chácara</MenuItem>
                        <MenuItem value="Colônia">Colônia</MenuItem>
                        <MenuItem value="Condomínio">Condomínio</MenuItem>
                        <MenuItem value="Conjunto">Conjunto</MenuItem>
                        <MenuItem value="Distrito">Distrito</MenuItem>
                        <MenuItem value="Esplanada">Esplanada</MenuItem>
                        <MenuItem value="Estação">Estação</MenuItem>
                        <MenuItem value="Favela">Favela</MenuItem>
                        <MenuItem value="Feira">Feira</MenuItem>
                        <MenuItem value="Jardim">Jardim</MenuItem>
                        <MenuItem value="Ladeira">Ladeira</MenuItem>
                        <MenuItem value="Lago">Lago</MenuItem>
                        <MenuItem value="Lagoa">Lagoa</MenuItem>
                        <MenuItem value="Largo">Largo</MenuItem>
                        <MenuItem value="Loteamento">Loteamento</MenuItem>
                        <MenuItem value="Morro">Morro</MenuItem>
                        <MenuItem value="Núcleo">Núcleo</MenuItem>
                        <MenuItem value="Passarela">Passarela</MenuItem>
                        <MenuItem value="Pátio">Pátio</MenuItem>
                        <MenuItem value="Quadra">Quadra</MenuItem>
                        <MenuItem value="Recanto">Recanto</MenuItem>
                        <MenuItem value="Residencial">Residencial</MenuItem>
                        <MenuItem value="Setor">Setor</MenuItem>
                        <MenuItem value="Sítio">Sítio</MenuItem>
                        <MenuItem value="Travessa">Travessa</MenuItem>
                        <MenuItem value="Trecho">Trecho</MenuItem>
                        <MenuItem value="Trevo">Trevo</MenuItem>
                        <MenuItem value="Vale">Vale</MenuItem>
                        <MenuItem value="Vereda">Vereda</MenuItem>
                        <MenuItem value="Via">Via</MenuItem>
                        <MenuItem value="Viaduto">Viaduto</MenuItem>
                        <MenuItem value="Viela">Viela</MenuItem>

                    </Select>
                    <FormHelperText>{errors["logradouro"] || "Escolha um logradouro"}</FormHelperText>
                </FormControl>
                <TextField
                    label="Endereço"
                    value={userData["endereco"]}
                    onChange={(e) => setUserData({ ...userData, "endereco": e.target.value })}
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    error={!!errors["endereco"]}
                    helperText={errors["endereco"]}
                />
                <TextField
                    label="Número"
                    value={userData["numero_casa"]}
                    onChange={(e) => setUserData({ ...userData, "numero_casa": e.target.value.replace(/\D/g, "") })} // Remove caracteres não numéricos
                    onInput={(e) => {
                        e.target.value = e.target.value.replace(/\D/g, ""); // Garantia de apenas números durante a entrada
                    }}
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    error={!!errors["numero_casa"]}
                    helperText={errors["numero_casa"]}
                />
                <TextField
                    label="Bairro"
                    value={userData["bairro"]}
                    onChange={(e) => setUserData({ ...userData, "bairro": e.target.value })}
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    error={!!errors["bairro"]}
                    helperText={errors["bairro"]}
                />
                <FormControl margin="normal" variant="outlined" color="secondary">
                    <InputLabel>Cidade</InputLabel>
                    <Select
                        label="Cidade"
                        value={userData["cidade"]}
                        onChange={(e) => setUserData({ ...userData, "cidade": e.target.value })}
                    >
                        <MenuItem value="Porto Seguro">Porto Seguro</MenuItem>
                        <MenuItem value="Arraial">Arraial</MenuItem>
                        <MenuItem value="Santa Cruz Cabrália">Santa Cruz Cabrália</MenuItem>
                        <MenuItem value="Coroa Vermelha">Coroa Vermelha</MenuItem>
                        <MenuItem value="Belmonte">Belmonte</MenuItem>
                        <MenuItem value="Itabatã">Itabatã</MenuItem>
                        <MenuItem value="Nova Viçosa">Nova Viçosa</MenuItem>
                        <MenuItem value="Mucuri">Mucuri</MenuItem>
                    </Select>
                    <FormHelperText>{errors["cidade"] || "Selecione sua cidade"}</FormHelperText>
                </FormControl>
                <TextField
                    label="Estado"
                    value="Bahia"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    disabled
                />
                <TextField
                    label="Complemento"
                    value={userData["complemento"]}
                    onChange={(e) => setUserData({ ...userData, "complemento": e.target.value })}
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                />
                <TextField
                    label="Referência"
                    value={userData["referencia"]}
                    onChange={(e) => setUserData({ ...userData, "referencia": e.target.value })}
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    error={!!errors["referencia"]}
                    helperText={errors["referencia"]}
                />
            </div>
            <div style={{ display: 'flex', gap: 10, margin: 10 }}>
                <ButtonNextStep onClick={() => setStep(1)}>VOLTAR</ButtonNextStep>
                <ButtonNextStep onClick={handleNextStep}>PRÓXIMO</ButtonNextStep>
            </div>
        </StepperTwoArea>
    );
}
