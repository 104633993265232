import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaGlobeAmericas } from "react-icons/fa";
import { LuCheckCircle2 } from "react-icons/lu";

import Logo from '../../assets/logo_branca.png';

import StepperOneRegister from "../../components/StepperOneRegister/StepperOneRegister";
import StepperTwoRegister from '../../components/StepperTwoRegister/StepperTwoRegister';
import StepperThreeRegister from '../../components/StepperThreeRegister/StepperThreeRegister';

import { Stepper, StepLabel, Step } from "@mui/material";

import { multiStepContext } from './script';

import { RegisterClientStyle, Header, Main, PlanArea, PlansAreaRegister, RegisterAreaData } from './styled.js';
import FooterGlobal from '../../components/FooterGlobal/index.jsx';

function RegisterClient() {

    const location = useLocation();

    const { planTitle, planValue, utmTerm, beneficios } = location.state || {};

    const { currentStep, setUserData } = useContext(multiStepContext);


    useEffect(() => {
        console.log(beneficios)
        if (planValue) {
            setUserData((prevData) => ({
                ...prevData,
                plano_valor: planValue
            }));
        }
    }, [planValue, setUserData])

    useEffect(() => {
        if (utmTerm) {
            setUserData((prevData) => ({
                ...prevData,
                utm_term: utmTerm
            }));
        }
    }, [utmTerm, setUserData])

    useEffect(() => {
        if (planTitle) {
            setUserData((prevData) => ({
                ...prevData,
                plano: planTitle
            }));
        }
    }, [planTitle, setUserData])



    function showStep(step) {
        switch (step) {
            case 1:
                return <StepperOneRegister />
            case 2:
                return <StepperTwoRegister />
            case 3:
                return <StepperThreeRegister />
            default:
                return <StepperOneRegister />
        }
    }

    return (
        <div>
            <RegisterClientStyle>
                <Header>
                    <div className="headerArea">
                        <span>Netcenter</span>
                        <span>Porto Seguro - Ba</span>
                    </div>
                </Header>

                <Main>

                    <RegisterAreaData>

                        <Stepper style={{ margin: '10px 20px' }} activeStep={currentStep - 1} orientation="horizontal">
                            <Step>
                                <StepLabel>Dados Pessoais</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Endereço</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Concluir</StepLabel>
                            </Step>
                        </Stepper>

                        {showStep(currentStep)}

                    </RegisterAreaData>

                    <PlanArea>
                        <PlansAreaRegister>
                            <div className="seuPedido">
                                Seu pedido
                            </div>

                            <div className="containerPedido">
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: 5 }}>
                                    <FaGlobeAmericas />
                                    <span>{planTitle}</span>
                                </div>

                                <div className="descricaoPlano">
                                    {beneficios && beneficios.map((benefit, index) => (
                                        <div key={index} style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
                                            <LuCheckCircle2 size={15} color="green" />
                                            <p>{benefit}</p>
                                        </div>
                                    ))}
                                </div>

                                <div className="valorPlanoArea">
                                    <span>
                                        R$ {planValue}
                                    </span>
                                </div>
                            </div>

                        </PlansAreaRegister>
                    </PlanArea>

                </Main>

            </RegisterClientStyle>

        </div>
    );
};

export default RegisterClient;