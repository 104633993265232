import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";


import {
    Body,
    Header,
    Main
} from "./styled";


const API_URl = 'http://localhost:4000';

function Login() {

    const navigate = useNavigate();
    // Definindo os states para email e password
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        localStorage.removeItem('token'); // Remove o token do localStorage ao acessar a página de login
    }, []);


    // Função de submit (opcional, pode ser usada posteriormente)
    const handleLogin = async () => {
        // Fazendo a requisição para a API de login
        try {
            const response = await fetch(`${API_URl}/login`, { // URL do seu backend
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password }) // Envia email e password no corpo da requisição
            });

            const data = await response.json();
            console.log(data)

            if (response.ok) {
                // Se a resposta for OK (200), salva o token e navega para a página /admin
                localStorage.setItem('token', data.token); // Salva o token JWT no localStorage
                navigate('/admin'); // Redireciona para a página /admin
            } else {
                // Caso haja erro no login, define a mensagem de erro
                setError(data.error || "Erro desconhecido");
            }
        } catch (err) {
            setError("Erro no servidor. Tente novamente mais tarde.");
        }
    };

    return (
        <Body>
            <Header>Login</Header>
            <Main>
                <div>
                    <TextField
                        placeholder="E-mail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                    />

                    <TextField
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                    />

                    <button id="btnEntrar" onClick={handleLogin}>
                        Entrar
                    </button>

                    {/* Exibe a mensagem de erro se houver */}
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </div>
            </Main>
        </Body>
    );
}

export default Login;
