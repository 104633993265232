import React, { useState, useContext } from 'react';
import App from '../../App';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import RegisterClientAndSales from '../../services/registerClient';

export const multiStepContext = React.createContext();

const StepContext = () => {
    const [currentStep, setStep] = useState(1);
    const [userData, setUserData] = useState([]);
    const [finalData, setFinalData] = useState([]);
    const [error, setError] = useState(false); // Estado para erro
    const [success, setSuccess] = useState(false); // Estado para sucesso
    const [errorMessage, setErrorMessage] = useState(""); // Mensagem de erro

    const [openSnackbar, setOpenSnackbar] = useState(false); // Controle do Snackbar

    function submitData() {
        if (!userData.nome || !userData.telefone || !userData.plano_valor) {
            alert("Preencha todos os campos obrigatórios!");
            return; // Impede que continue se algum campo obrigatório estiver vazio
        }

        // Reinicia estados de erro e sucesso
        setError(false);
        setSuccess(false);

        setFinalData((prevFinalData) => [...prevFinalData, userData]);

        const whatsappUrl = `https://api.whatsapp.com/send?phone=5573999146119`;

        // Chamar o serviço para enviar os dados
        RegisterClientAndSales(userData)
            .then((response) => {
                console.log("Dados enviados com sucesso", response);
                setSuccess(true); // Define como sucesso
                setErrorMessage(""); // Limpa qualquer mensagem de erro anterior
                setOpenSnackbar(true); // Abre o Snackbar para sucesso
                setStep(1);
    
                // Define um timeout de 3 segundos antes de abrir o WhatsApp
                setTimeout(() => {
                    window.open(whatsappUrl, "_blank"); // Abre o WhatsApp em uma nova aba
                }, 2000); // 3000 milissegundos = 3 segundos
            })
            .catch((error) => {
                setSuccess(false); // Garante que o sucesso esteja false
                if (error.response && error.response.data && error.response.data.error) {
                    // Pega a mensagem de erro do backend
                    setError(true);
                    setErrorMessage(error.response.data.error);

                    setTimeout(() => {
                        window.open(whatsappUrl, "_blank"); // Abre o WhatsApp em uma nova aba
                    }, 2000); // 3000 milissegundos = 3 segundos

                } else {
                    setError(true);
                    setErrorMessage(error.message);
                    console.log(error)
                }
                setOpenSnackbar(true); // Abre o Snackbar para erro
            });
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false); // Fecha o Snackbar manualmente
    };

    return (
        <div>
            {/* Snackbar para exibir mensagem de erro ou sucesso */}
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000} // Duração de 2 segundos
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {error ? (
                    <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                ) : success ? (
                    <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                        Cadastro feito com sucesso!
                    </Alert>
                ) : null}
            </Snackbar>

            <multiStepContext.Provider
                value={{
                    currentStep, setStep,
                    userData, setUserData,
                    finalData, setFinalData,
                    submitData
                }}
            >
                <App />
            </multiStepContext.Provider>
        </div>
    );
}

export default StepContext;
