import axios from 'axios';

const apiUrl = "http://localhost:4000";

const RegisterClientAndSales = async (userData) => {
    try {
        const response = await axios.post(`${apiUrl}/api/sales/client/register`, userData);
        console.log('Resposta da API:', response.data); // Tratando a resposta da API
        return response.data; // Retorna os dados da resposta
    } catch (error) {
        // console.error('Erro ao fazer a requisição:', error); // Tratando erros
        throw error; // Propaga o erro para o chamador
    }
};

export default RegisterClientAndSales;
