import styled from "styled-components";
import {size, breakpoints } from '../../../../styles/breakpoints';

export const Body = styled.body`
    display: flex;
    flex-direction: column;
    height: 100vh;

`;

export const Header = styled.header`
    display: flex;
    width: 100%;
    height: 80px;


`;

export const Main = styled.main`
    min-width: ${size.md};
    display: grid;
    min-height: 100%;
    grid-area: "aside, main";
    grid-template-columns: 200px auto;

    .aside {
        display: flex;
        flex-direction: column;
        padding: 30px 10px;
        justify-content: start;
        text-align: left;
        grid-area: 'aside';
        background-color: #1d2261;
    }

    .main {
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        grid-area: 'main';
        background-color: #040832;

        .mainArea {
            display: flex;
            flex-direction: column;
            gap: 40px;
            align-items: center;
            margin: 30px;
            display: flex;
            width: 90%;
            

            h3 {
                width: 100%;
                text-align: center;
                color: #fff;
            }

            .link-utm {
                border: 1px solid red;
                padding: 10px 34px;
                border-radius: 20px;
            }

            .section-vendas-realizadas {
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 500px;
                background-color: #1d2261;
                border-radius: 10px;
            }

            .header-vendas {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin:  30px 100px;
            }

            .main-vendas {
                display: flex;
                flex-direction: column;
                margin: 30px 100px;
                height: 100%;

                .saldo-area {
                    background: #040832;
                    color: #fff;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    padding: 20px;
                    justify-content: start;
                    align-items: center;
                }
            }
        }

    }

    #btnEntrar {
        padding: 10px;
        margin: 10px;
        border-radius: 5px;
        background-color: #c2c2c2;
        border-color: green;
    }
`;