import styled from "styled-components";
import {breakpoints} from "../../styles/breakpoints";

export const RegisterClientStyle = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    margin: 0;
    padding: 0;
`;

export const Header = styled.header`
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    font-size: 20px;
    color: #b1b1b6;
    background-color: #060654;

    .headerArea {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        max-width: 1020px;
    }
`;

export const Main = styled.main`
    padding: 0;
    display: grid;
    align-self: center;
    background-color: rgb(255, 255, 255);
    max-width: 1070px;
    min-height: 100vh;
    padding: 30px 0 ;
    grid-template-columns: 1fr 400px;
    grid-template-areas: "register planArea";

    @media ${breakpoints.md} {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas: 
        "planArea"
        "register"
        ;
        height: fit-content;
        padding: 0;
    }

    @media ${breakpoints.sm} {
        /* Para telas pequenas */
        display: flex;
        flex-direction: column-reverse; /* Coloca o último item no topo */
    }
`;

export const RegisterAreaData = styled.div`
    grid-area: register;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const PlanArea = styled.div`
    grid-area: planArea;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
`;

export const PlansAreaRegister = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    text-align: center;
    justify-content: start;
    width: 370px;
    margin: 10px;
    background-color: rgba(229, 235, 235, 0.2);
    color: #1e1d1d;
    font-weight: bold;
    border-radius: 10px;
    border: 1px solid #c3c3c3;
    box-shadow: 1px 3px 7px #c2c2c2;

    @media ${breakpoints.md}{
        width: 90%;
        margin: 10px 10px 30px 10px;
    }

    .seuPedido {
        color: #fff;
         border-radius: 10px 10px 0 0;
         justify-content: center;
         display: flex;
         align-items: center;
         background: #00008B; 
         width: 100%;
         height: 70px; 
         align-self: start;
    }

    .containerPedido {
        gap: 2px; 
        margin: 20px;
        display: flex;
        flex-direction: column;
        text-align: left;
        align-items: start;
        justify-content: start;
    }

    .descricaoPlano {
        width: 100%;
        display: flex;
        align-self: self-start;
        justify-content: start;
        flex-direction: column;
        font-size: 14px;
        color: #4F4F4F;
        font-weight: bold;
        margin: 10px 0 30px 0;
    }

    .valorPlanoArea {
        align-self: center;
        display: flex;
        flex-direction: column; 
        justify-content: end; 
        width: 80%; 
        font-size: 35px; 
        color: green;
        text-align: center; 
        height: 100%; 
        border-radius: 5px;
        background-color: #c2c2c2;
        box-shadow: 1px 4px 5px #FFFAFA;
        padding: 15px;
        font-family: Ubuntu, sans-serif;
    }
`;