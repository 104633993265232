import styled from "styled-components";
import {breakpoints} from "../../styles/breakpoints";

export const StepOneRegister = styled.div`
    position: relative;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: start; 
    justify-content: center;
    align-items: center;
    padding: 20px 0;
`

export const StepAreaText = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px 20px;
    padding: 10px;
    max-width: 1010px;

    @media screen and (max-width: 1200px) and (min-width: 801px){
        .stepOneRegister .stepAreaText {
            justify-content: center; /* Alinhado ao centro entre 800px e 1200px */
        }
    }


    @media screen and (max-width: 800px) {
        .stepOneRegister .stepAreaText {
            justify-content: center; /* Alinhado ao centro entre 800px e 1200px */
        }
    }
`
export const ButtonNextStep = styled.button`
    position: relative;
    border-color: rgba(0, 0, 0, 0.1);
    font-size: 16px;
    font-weight: bold;
    color: #000;
    padding: 15px 30px;
    border-radius: 10px;
    box-shadow: 1px 3px 2px #c2c2c2;
    background-color: transparent;
`