import React, { useContext, useState } from "react";
import { MenuItem, Select, FormControl, InputLabel, FormHelperText } from "@mui/material";
import { multiStepContext } from "../../pages/RegisterClient/script";
import { ButtonNextStep } from '../StepperOneRegister/styles';

import { Main } from './styled';

export default function StepperThreeRegister() {
    const [errors, setErrors] = useState({});
    const { setStep, userData, setUserData, submitData } = useContext(multiStepContext);

    const handleNextStep = () => {
        const melhorPeriodoError = userData["melhor_periodo"] ? null : "Selecione um período para instalação.";

        setErrors({
            melhor_periodo: melhorPeriodoError
        });

        // Verifica se há erros
        if (!melhorPeriodoError) {
            submitData(); // Conclui o processo
        }
    };

    return (
        <Main>
            <p>Escolha o período manhã ou tarde para sua instalação!</p>
            <div>
                <FormControl margin="normal" variant="outlined" color="secondary">
                    <InputLabel>Melhor período</InputLabel>
                    <Select
                        label="Melhor período"
                        value={userData["melhor_periodo"] || ""}
                        onChange={(e) => setUserData({ ...userData, "melhor_periodo": e.target.value })}
                        error={!!errors["melhor_periodo"]}
                    >
                        <MenuItem value="Manhã">Manhã</MenuItem>
                        <MenuItem value="Tarde">Tarde</MenuItem>
                    </Select>
                    <FormHelperText>{errors["melhor_periodo"] || "Selecione seu período para instalação"}</FormHelperText>
                </FormControl>
            </div>
            <div style={{ display: 'flex', gap: 10, margin: 10 }}>
                <ButtonNextStep onClick={() => setStep(2)}>VOLTAR</ButtonNextStep>
                <ButtonNextStep onClick={handleNextStep}>CONCLUIR</ButtonNextStep>
            </div>
        </Main>
    );
}
