import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";

export const Footer = styled.footer`
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
    padding: 20px;
    font-weight: bold;
    height: 100%;
    border: 1px solid red;

`